<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5">List Price</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model.lazy="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="name" v-model="data.name" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-textarea class="w-full" maxlength ="255" name="Notes" v-model="data.notes" readonly />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                         <vs-input class="w-full" name="name" v-model="data.valid_from" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                         <vs-input class="w-full" name="name" v-model="data.valid_to" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Status Active</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <div class="vx-col w-full">
                            <vs-switch v-model="data.is_active"/>
                        </div>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Items</span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4 mt-2">
					<div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-3/5" style="display: flex">
                                <span style="margin: auto; width: 5%" class="mr-3" v-if="items[indextr].num != 0" >{{items[indextr].num}}.</span>
                                <span style="margin: auto; width: 5%" class="mr-3" v-else></span>
                                <vs-input label="Item" class="w-full" :value="items[indextr].label" readonly/>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/2 mr-1">
                                    <vs-input label="Price" :value="items[indextr].price"  class="w-full" name="Price" readonly/>
                                </div>
                                <div class="vx-col w-1/2 mr-1">
                                    <vs-input label="Discount Price" :value="items[indextr].discount_price" class="w-full" name="Discount Price" readonly/>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
                <content-placeholders v-if="statusPlaceholder == true" class="mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-5/5 w-full mb-2">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <content-placeholders-text style="margin: auto; width: 5%" class="ml-4 mr-3" :lines="1" />
                                <content-placeholders-text class="w-full" :lines="2" />
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </content-placeholders>                
            </vx-card>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    created() {
    },
    data() {
        return {
            button: true,
            items: [],
            notes: "",
            statusPlaceholder: true,
            data: {
                code: "",
                name: "",
                notes: "",
                valid_from: "",
                valid_to: "",
                is_active: true
            },
        };
    },
    methods: {
        handleBack() {
            this.$router.push("/master/pricing-management-src");
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
            console.log("getData")
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/price-src/"+this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {                    
                    this.statusPlaceholder = true
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.notes = resp.data.notes;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.is_active = resp.data.status_active;
                    setTimeout(function(){
                        _this.items = []
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_unit_id: resp.data.list[k].item_unit_id,
                                item_name: resp.data.list[k].item_name,
                                price: resp.data.list[k].price.toString(),
                                discount_price: resp.data.list[k].discount_price.toString(),
                                valid_from: resp.data.list[k].valid_from,
                                valid_to: resp.data.list[k].valid_to,
                                status_item: "old",
                            })                            
                        }
                        if(_this.items.length == resp.data.list.length){
                            _this.statusPlaceholder = false
                        }
                    }, 0);
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    mounted() {
        this.getData();
    },
    computed: {},
    watch: {}
};
</script>